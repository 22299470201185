//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// import {getOrgTree, getUserByName} from '@/api/org'
import consumer_department_getSubordinateDept
  from "@/lib/data-service/haolv-default/consumer_department_getSubordinateDept";
import consumer_apply_staffAndCustomerList
  from "@/lib/data-service/haolv-default/consumer_apply_staffAndCustomerList";
import {selectTmcRole, selectTmcRank} from '@/lib/data-service/haolv-default/design';
export default {
  name: "OrgPicker",
  components: {},
  props: {
    title: {
      default: '请选择',
      type: String
    },
    type: {
      default: 'org', //org选择部门/人员  user-选人  dept-选部门 role-选角色 rank-职级
      type: String
    },
    multiple: { //是否多选
      default: false,
      type: Boolean
    },
    selected: {
      default: () => {
        return []
      },
      type: Array
    },
    maxSelect: { // 最大可选择人数
      type: Number,
      default: 0, // 0 是无限
    }
  },
  data() {
    return {
      visible: false,
      loading: false,
      checkAll: false,
      nowDeptId: null,
      isIndeterminate: false,
      searchUsers: [],
      nodes: [],
      select: [],
      search: '',
      deptStack: [],
      currentPage: 1,
      total: 0,
    }
  },
  computed: {
    deptStackStr() {
      return String(this.deptStack.map(v => v.name)).replaceAll(',', ' > ')
    },
    orgs() {
      return !this.search || this.search.trim() === '' ? this.nodes : this.searchUsers
    },
    showUsers(){
      return this.search || this.search.trim() !== ''
    }
  },
  methods: {
    show() {
      this.visible = true;

      this.init()
      this.getOrgList()
    },
    orgItemClass(org){
      return {
        'org-item': true,
        'org-dept-item': org.type === 'dept',
        'org-user-item': org.type === 'user',
        'org-role-item': org.type === 'role',
          'org-rank-item': org.type === 'rank'
      }
    },
    disableDept(node) {
      return this.type === 'user' && 'dept' === node.type
    },
    getOrgList() {
      this.loading = true;
      this.currentPage = 1;
      this.getOrgTree({deptId: this.nowDeptId, type: this.type}).then(rsp => {
        console.log(rsp);
        let deptList = [];
        let userList = [];
        let roleList = [];
        let rankList = [];
        switch (this.type) {
          case 'org':
            deptList = rsp[0].datas.deptVos;
            deptList.forEach(value=>{
              value.type = 'dept';
              value.name = value.deptName;
              value.id = value.deptId;
            })
            userList = rsp[1].datas.list;
            this.total = rsp[1].datas.totalPage;
            userList.forEach(value=>{
              value.type = 'user';
              value.id = value.userId;
            })
            break
          case 'user':
            deptList = rsp[0].datas.deptVos;
            deptList.forEach(value=>{
              value.type = 'dept';
              value.name = value.deptName;
              value.id = value.deptId;
            })
            userList = rsp[1].datas.list;
            this.total = rsp[1].datas.totalPage;
            userList.forEach(value=>{
              value.type = 'user';
              value.id = value.userId;
            })
            break;
          case 'dept':
            deptList = rsp[0].datas.deptVos;
            deptList.forEach(value=>{
              value.type = 'dept';
              value.name = value.deptName;
              value.id = value.deptId;
            })
            break;
          case 'role':
            roleList = rsp[0].result;
            roleList.forEach(value => {
              value.type = 'role';
              value.name = value.roleName;
            })
            break;
          case 'rank':
            rankList = rsp[0].result;
            rankList.forEach(value => {
              value.type = 'rank';
              value.name = value.rankName;
              value.id = value.rankCode;
            })
            break;
        }

        this.loading = false
        this.nodes = [...deptList, ...userList, ...roleList, ...rankList];
        console.log(this.nodes);
        this.selectToLeft()
      }).catch(err => {
        console.log(err);
        this.loading = false
        //this.$message.error(err.response.data)
      })
    },
    getShortName(name) {
      if (name) {
        return name.length > 2 ? name.substring(1, 3) : name;
      }
      return '**'
    },
    searchUser() {
      let userName = this.search.trim()
      this.searchUsers = []
      this.currentPage = 1;
      this.loading = true
      this.getUserByName({userName: userName}).then(rsp => {
        this.loading = false
        let userList = rsp.datas.list;
        this.total = rsp.datas.totalPage;
        userList.forEach(value=>{
          value.type = 'user';
          value.id = value.userId;
        })
        this.searchUsers = userList
        this.selectToLeft()
      }).catch(err => {
        this.loading = false
        this.$message.error("接口异常")
      })
    },
    selectToLeft() {
      let nodes = this.search.trim() === '' ? this.nodes : this.searchUsers;
      nodes.forEach(node => {
        for (let i = 0; i < this.select.length; i++) {
          if (this.select[i].id === node.id) {
            node.selected = true;
            break;
          } else {
            node.selected = false;
          }
        }
      })
    },
    selectChange(node) {
      if (node.selected) {
        this.checkAll = false;
        for (let i = 0; i < this.select.length; i++) {
          if (this.select[i].id === node.id) {
            this.select.splice(i, 1);
            break;
          }
        }
        node.selected = false;
      } else if (!this.disableDept(node)) {
        // 如果超过maxselect就不能选
        if (this.maxSelect !== 0 && this.select.length >= this.maxSelect) {
          return;
        }
        node.selected = true
        let nodes = this.search.trim() === '' ? this.nodes : this.searchUsers;
        if (!this.multiple) {
          nodes.forEach(nd => {
            if (node.id !== nd.id) {
              nd.selected = false
            }
          })
        }
        if (node.type === 'dept') {
          if (!this.multiple) {
            this.select = [node]
          } else {
            this.select.unshift(node);
          }
        } else {
          if (!this.multiple) {
            this.select = [node]
          } else {
            this.select.push(node);
          }
        }
      }
    },
    noSelected(index) {
      let nodes = this.nodes;
      for (let f = 0; f < 2; f++) {
        for (let i = 0; i < nodes.length; i++) {
          if (nodes[i].id === this.select[index].id) {
            nodes[i].selected = false;
            this.checkAll = false;
            break;
          }
        }
        nodes = this.searchUsers;
      }
      this.select.splice(index, 1)
    },
    handleCheckAllChange() {
      this.nodes.forEach(node => {
        if (this.checkAll) {
          if (!node.selected && !this.disableDept(node)) {
            console.log('this.s', this.select);
            // 限制人数
            if (this.maxSelect === 0 || this.select.length < this.maxSelect) {
              node.selected = true
              this.select.push(node)
            }

          }
        } else {
          node.selected = false;
          for (let i = 0; i < this.select.length; i++) {
            if (this.select[i].id === node.id) {
              this.select.splice(i, 1);
              break;
            }
          }
        }
      })
    },
    nextNode(node) {
      if (node.selected) {
        return
      }
      this.nowDeptId = node.id
      this.deptStack.push(node)
      this.getOrgList()
    },
    beforeNode() {
      if (this.deptStack.length === 0) {
        return;
      }
      if (this.deptStack.length < 2) {
        this.nowDeptId = null
      } else {
        this.nowDeptId = this.deptStack[this.deptStack.length - 2].id
      }
      this.deptStack.splice(this.deptStack.length - 1, 1);
      this.getOrgList()
    },
    recover() {
      this.select = []
      this.nodes.forEach(nd => nd.selected = false)
    },
    selectOk() {
      this.$emit('ok', Object.assign([], this.select.map(v => {
        v.avatar = undefined
        return v
      })))
      this.visible = false
      this.recover()
    },
    clearSelected(){
      this.$confirm('您确定要清空已选中的项?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.recover()
      })
    },
    close() {
      this.$emit('close')
      this.recover()
    },
    init() {
      this.currentPage = 1;
      this.checkAll = false;
      this.nowDeptId = null;
      this.deptStack = [];
      this.nodes = []
      this.select = Object.assign([], this.selected)
      this.selectToLeft()
    },
    getOrgTree(dept) {
      console.log(dept);
      let apiList = [];
      switch (dept.type) {
        case 'org':
          apiList.push(consumer_department_getSubordinateDept({
            bookingScene: 1,
            isSelectCustomer: true,
            deptId: dept && dept.deptId ? dept.deptId : 0,
            'currentPage': 1,
            'pageSize': 100,
          }));
          apiList.push(consumer_apply_staffAndCustomerList({
            bookingScene: 1,
            deptId: dept && dept.deptId ? dept.deptId : 0,
            'currentPage': this.currentPage,
            'pageSize': 20,
          }))
          break
        case 'user':
          apiList.push(consumer_department_getSubordinateDept({
            bookingScene: 1,
            isSelectCustomer: true,
            deptId: dept && dept.deptId ? dept.deptId : 0,
            'currentPage': 1,
            'pageSize': 100,
          }));
          apiList.push(consumer_apply_staffAndCustomerList({
            bookingScene: 1,
            deptId: dept && dept.deptId ? dept.deptId : 0,
            'currentPage': this.currentPage,
            'pageSize': 20,
          }))
          break;
        case 'dept':
          apiList.push(consumer_department_getSubordinateDept({
            bookingScene: 1,
            isSelectCustomer: true,
            deptId: dept && dept.deptId ? dept.deptId : 0,
            'currentPage': 1,
            'pageSize': 20,
          }));
          break;
        case 'role':
          apiList.push(selectTmcRole({
            companyId: this.$store.state.userInfo.companyId,
          }));
          break;
        case 'rank':
          apiList.push(selectTmcRank({
            companyId: this.$store.state.userInfo.companyId,
          }));
          break;
      }
      return Promise.all(apiList);
    },
    getUserByName(userName) {
      return consumer_apply_staffAndCustomerList({
        bookingScene: 1,
        deptId: -1,
        keyword: userName.userName,
        'currentPage': 1,
        'pageSize': 100,
      });
    },

    more() {
      this.currentPage++;
      if (this.currentPage > this.total) {
        return;
      }
      consumer_apply_staffAndCustomerList({
        bookingScene: 1,
        deptId: this.nowDeptId ? this.nowDeptId : 0,
        'currentPage': this.currentPage,
        'pageSize': 20,
      }).then((res)=>{
        console.log(res);
        let userList = res.datas.list;
        userList.forEach(value=>{
          value.type = 'user';
          value.id = value.userId;
        });
        this.nodes = this.nodes.concat(userList);
        console.log(this.nodes);
        this.$forceUpdate();
        this.selectToLeft()
      });
    },

    aa(val) {
      console.log('aaaaa', val);
    }
  }
}

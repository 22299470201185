const Q = require(`q`);
const _ = require(`underscore`);
const to_boolean = require(`nllib/src/convert/to_boolean`);
const __request = require(`./__request/__request_contentType_json`);

// 接口文档地址：
// http://api-alpha.wholeok.com/doc.html#/yinzhilv-tmc/API/getSubordinateDeptUsingPOST
const consumer_apply_staffAndCustomerList = (pParameter) => {
    if (!pParameter) pParameter = {};

    const p = {
        method: 'post',
        urlSuffix: '/consumer/apply/staffAndCustomerList',
        data: pParameter,
    };

    return Q.when()
        .then(function () {
            return __request(p);
        })
        .then(function (data) {
            const datas = data.datas;
            if (!datas) {
                const msg = `consumer_apply_staffAndCustomerList parameter error, code=001`;
                console.error(msg);
                console.trace();
                return Q.reject({msg: msg});
            }

            const list = datas.list;
            if (!_.isArray(list)) {
                const msg = `consumer_apply_staffAndCustomerList parameter error, code=001`;
                console.error(msg);
                console.trace();
                return Q.reject({msg: msg});
            }

            return Q.resolve(data);
        })
        .catch(function (ex) {
            if (ex) {
                const enable_log_error = !ex.disable_log_error;
                if (enable_log_error) {
                    console.error(ex);
                    console.trace();
                    if (ex.stack) {
                        console.error(ex.stack);
                    }
                }
            }
            return Q.reject(ex);
        })
        ;
};
module.exports = consumer_apply_staffAndCustomerList;
